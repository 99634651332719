<template>
  <b-form-group
    :id="`group-${id}`"
    :label="computedLabel"
    :label-cols="computedLabelCols['xs']"
    :label-cols-sm="computedLabelCols['sm']"
    :label-cols-md="computedLabelCols['md']"
    :label-cols-lg="computedLabelCols['lg']"
    :label-cols-xl="computedLabelCols['xl']"
    :label-class="computedReadOnly ? '' : 'required'"
    :label-for="`input-${id}`"
  >
    <span v-if="computedReadOnly" class="labeled">{{ value.libelle }}</span>
    <b-form-tag v-else-if="value" @remove="selectIlot(null)" variant="primary">
      {{ value.libelle }}
    </b-form-tag>
    <template v-else>
      <b-form-input
        ref="filtre"
        :id="`input-${id}`"
        type="text"
        autocomplete="off"
        v-model="filtre"
        @blur="state = true"
      ></b-form-input>
      <b-form-invalid-feedback
        v-if="state"
        :id="`feedback-${id}`"
        :state="!!value"
      >
        L'ilot est obligatoire
      </b-form-invalid-feedback>
      <b-popover
        boundary="viewport"
        placement="bottom"
        triggers="focus"
        :target="`input-${id}`"
      >
        <b-container style="cursor: pointer">
          <b-row v-for="ilot in options" :key="ilot.libelle">
            <b-col @click="selectIlot(ilot)">
              Choisir l'ilot "{{ ilot.libelle }}"
            </b-col>
          </b-row>
          <b-row v-if="creationAllowed">
            <b-col @click="creerIlot">
              <b-icon-plus></b-icon-plus>
              Cr&eacute;er l'ilot "{{ filtre }}"
            </b-col>
          </b-row>
        </b-container>
      </b-popover>
    </template>
  </b-form-group>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { computed as inputsComputed } from "../../services/inputs.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "SelectIlotComponent",
  /**
   * value = valeur du select (objet Ilot)
   * label = label du select
   * labelCols = propriété label-cols du form-group (lecture seule uniquement)
   * readOnly = pour afficher le controle en lecture seule
   */
  props: ["value", "label", "labelCols", "readOnly"],
  data() {
    return {
      id: `ilot-${uuidv4()}`,
      filtre: "",
      state: false,
    };
  },
  computed: {
    ...inputsComputed,
    computedReadOnly() {
      return this.readOnly === true || this.readOnly === "true";
    },
    computedLabel() {
      return this.label ?? "Ilot";
    },
    computedFiltre() {
      return this.filtre?.trim().toLowerCase() ?? "";
    },
    options() {
      return this.$store.state.decl.ilots
        .filter((i) => i.libelle.toLowerCase().includes(this.computedFiltre))
        .sort(UtilsService.sortByStringProperty("libelle"))
        .slice(0, 10);
    },
    creationAllowed() {
      return (
        this.computedFiltre &&
        this.value?.libelle.toLowerCase() !== this.computedFiltre &&
        !this.options.find(
          (i) => i.libelle.toLowerCase() === this.computedFiltre
        )
      );
    },
  },
  methods: {
    creerIlot() {
      this.selectIlot({
        id: null,
        libelle: this.filtre,
      });
    },
    selectIlot(ilot) {
      this.$emit("input", ilot);
      this.filtre = null;
    },
    validate() {
      if (!this.value) {
        this.$refs.filtre.focus();
        throw new Error("Invalid field");
      }
    },
  },
};
</script>
