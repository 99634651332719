<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>
      <template>D&eacute;coupe de parcelle</template>
    </template>
    <b-container v-if="parcelle" class="context-container">
      <GenericReadonlyComponent
        label="Parcelle à découper :"
        :labelCols="{ md: 'auto' }"
      >
        {{ parcelle.ilot.libelle }} - {{ parcelle.libelle }} (<MontantComponent
          :value="parcelle.surfaceEmblavee - parcelle.surfaceRetiree"
        ></MontantComponent>
        ha)
      </GenericReadonlyComponent>
    </b-container>
    <b-container v-if="parcelle">
      <p v-if="ecartSurfaceEmblavee > 0.1">
        Attention, vous avez un &eacute;cart de
        <strong>surface admissible</strong> entre la parcelle d'origine
        (<MontantComponent :value="parcelle.surfaceEmblavee"></MontantComponent>
        ha) et les parcelles d&eacute;coup&eacute;es (<MontantComponent
          :value="surfaceEmblaveeTotale"
        ></MontantComponent>
        ha).
      </p>
      <p v-if="ecartSurfaceRetiree > 0.1">
        Attention, vous avez un &eacute;cart de
        <strong>surface abandonn&eacute;e</strong> entre la parcelle d'origine
        (<MontantComponent :value="parcelle.surfaceEmblavee"></MontantComponent>
        ha) et les parcelles d&eacute;coup&eacute;es (<MontantComponent
          :value="surfaceEmblaveeTotale"
        ></MontantComponent>
        ha).
      </p>
      <b-row>
        <template v-for="(_, index) in nbParcelles">
          <h6 :key="`title_${index}`" class="label">
            <strong>Parcelle d&eacute;coup&eacute;e {{ index + 1 }}</strong>
          </h6>
          <b-col
            :key="`col_${index}`"
            cols="12"
            class="mb-3 pt-2"
            style="border: 1px solid rgba(0, 0, 0, 0.1); border-radius: 10px"
          >
            <CustomInputComponent
              ref="inputLibelle"
              label="Libellé parcelle :"
              :labelCols="{ md: 6 }"
              rules="required|max:100"
              v-model="libelles[index]"
            ></CustomInputComponent>
            <CustomInputNumberComponent
              ref="surfaceEmblavee"
              label="Surface admissible : "
              :labelCols="{ md: 6 }"
              step="0.1"
              append="ha"
              :rules="`required|min_value:0.1|max_value:${parcelle.surfaceEmblavee}`"
              v-model="surfacesEmblavees[index]"
            ></CustomInputNumberComponent>
            <CustomInputNumberComponent
              ref="surfaceRetiree"
              label="Surface abandonnée : "
              :labelCols="{ md: 6 }"
              step="0.1"
              append="ha"
              :rules="`required|min_value:0|max_value:${surfacesEmblavees[index]}`"
              v-model="surfacesRetirees[index]"
            ></CustomInputNumberComponent>
          </b-col>
        </template>
      </b-row>
      <b-button-toolbar class="justify-content-center">
        <b-button-group>
          <b-button
            class="rounded-left"
            variant="outline-primary"
            :disabled="nbParcelles <= 2"
            @click="removeParcelle"
          >
            <b-icon-trash-fill></b-icon-trash-fill>
            <span class="d-none d-md-inline">&nbsp; Retirer une parcelle</span>
          </b-button>
          <b-button
            class="rounded-right"
            variant="primary"
            @click="addParcelle"
          >
            <b-icon-plus-circle-fill></b-icon-plus-circle-fill>
            <span class="d-none d-md-inline">&nbsp; Ajouter une parcelle</span>
          </b-button>
        </b-button-group>
      </b-button-toolbar>
    </b-container>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import GenericReadonlyComponent from "../inputs/GenericReadonlyComponent.vue";
import CustomInputComponent from "../inputs/CustomInputComponent.vue";
import CustomInputNumberComponent from "../inputs/CustomInputNumberComponent.vue";
import MontantComponent from "../MontantComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "DecoupeParcelleDialog",
  components: {
    GenericReadonlyComponent,
    CustomInputComponent,
    CustomInputNumberComponent,
    MontantComponent,
  },
  data: () => ({
    parcelle: null,
    libelles: [],
    surfacesEmblavees: [],
    surfacesRetirees: [],
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    nbParcelles() {
      return this.libelles.length;
    },
    surfaceEmblaveeTotale() {
      return this.surfacesEmblavees.reduce((acc, next) => acc + next, 0.0);
    },
    ecartSurfaceEmblavee() {
      return Math.abs(
        this.surfaceEmblaveeTotale - this.parcelle.surfaceEmblavee
      );
    },
    surfaceRetireeTotale() {
      return this.surfacesRetirees.reduce((acc, next) => acc + next, 0.0);
    },
    ecartSurfaceRetiree() {
      return Math.abs(this.surfaceRetireeTotale - this.parcelle.surfaceRetiree);
    },
  },
  methods: {
    addParcelle() {
      let nbParcelles = this.nbParcelles + 1;
      let surfaceEmblavee = this.parcelle.surfaceEmblavee / nbParcelles;
      let surfaceRetiree = this.parcelle.surfaceRetiree / nbParcelles;

      this.libelles.push(this.parcelle.libelle);
      this.surfacesEmblavees = new Array(nbParcelles).fill(surfaceEmblavee);
      this.surfacesRetirees = new Array(nbParcelles).fill(surfaceRetiree);
    },
    removeParcelle() {
      let nbParcelles = this.nbParcelles - 1;
      let surfaceEmblavee = this.parcelle.surfaceEmblavee / nbParcelles;
      let surfaceRetiree = this.parcelle.surfaceRetiree / nbParcelles;

      this.libelles = this.libelles.slice(0, nbParcelles);
      this.surfacesEmblavees = new Array(nbParcelles).fill(surfaceEmblavee);
      this.surfacesRetirees = new Array(nbParcelles).fill(surfaceRetiree);
    },
    show(options) {
      this.parcelle = UtilsService.deepCopy(options.parcelle);

      // On initialise par défaut avec 2 parcelles de tailles égales
      this.libelles = new Array(2).fill(this.parcelle.libelle);
      this.surfacesEmblavees = new Array(2).fill(
        this.parcelle.surfaceEmblavee / 2
      );
      this.surfacesRetirees = new Array(2).fill(
        this.parcelle.surfaceRetiree / 2
      );

      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      for (let i = 0; i < this.nbParcelles; i++) {
        await this.$refs.inputLibelle[i].validate();
        await this.$refs.surfaceEmblavee[i].validate();
        await this.$refs.surfaceRetiree[i].validate();
      }
      this.$refs["modal"].hide();
      this.resolvePromise({
        idParcelle: this.parcelle.id,
        libelles: this.libelles,
        surfacesEmblavees: this.surfacesEmblavees,
        surfacesRetirees: this.surfacesRetirees,
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
