<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>{{ title }}</template>
    <section v-if="parcelle">
      <SelectIlotComponent
        ref="inputIlot"
        label="Ilot :"
        :labelCols="{ md: 5 }"
        :readOnly="!ilotModifiable"
        v-model="parcelle.ilot"
      ></SelectIlotComponent>
      <CustomInputComponent
        ref="inputLibelle"
        label="Parcelle :"
        :labelCols="{ md: 5 }"
        :readOnly="readOnly"
        :disabled="parcelle.etatParcelle !== 'INTENTION'"
        rules="required|max:100"
        v-model="parcelle.libelle"
      ></CustomInputComponent>
      <CustomInputNumberComponent
        ref="inputSurfaceEmblavee"
        label="Surface admissible :"
        :labelCols="{ md: 5 }"
        append="ha"
        :readOnly="readOnly"
        :disabled="parcelle.etatParcelle !== 'INTENTION'"
        rules="required|min_value:0.1"
        v-model="parcelle.surfaceEmblavee"
      ></CustomInputNumberComponent>
      <CustomInputNumberComponent
        ref="inputSurfaceRetiree"
        label="Surface abandonnée :"
        :labelCols="{ md: 5 }"
        append="ha"
        :readOnly="readOnly"
        :disabled="
          parcelle.etatParcelle !== 'INTENTION' &&
          parcelle.etatParcelle !== 'SEMEE'
        "
        :rules="`required|min_value:0|max_value:${parcelle.surfaceEmblavee}`"
        v-model="parcelle.surfaceRetiree"
      ></CustomInputNumberComponent>
      <InputCommentaireComponent
        label="Commentaire parcelle :"
        :labelCols="{ md: 5 }"
        :readOnly="readOnly"
        v-model="parcelle.commentaire"
      ></InputCommentaireComponent>
    </section>
    <template #modal-footer>
      <ViewEditDialogButtons
        :readOnly="readOnly"
        @edit="mode = 'EDITION'"
        @cancel="cancel"
        @validate="validate"
      ></ViewEditDialogButtons>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import SelectIlotComponent from "../inputs/SelectIlotComponent.vue";
import CustomInputComponent from "../inputs/CustomInputComponent.vue";
import CustomInputNumberComponent from "../inputs/CustomInputNumberComponent.vue";
import InputCommentaireComponent from "../inputs/InputCommentaireComponent.vue";
import ViewEditDialogButtons from "./ViewEditDialogButtons.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "ParcelleEditionDialog",
  components: {
    SelectIlotComponent,
    CustomInputComponent,
    CustomInputNumberComponent,
    InputCommentaireComponent,
    ViewEditDialogButtons,
  },
  data: () => ({
    id: `parcelle-${uuidv4()}`,
    mode: null,
    parcelle: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  computed: {
    title() {
      if (this.mode === "CREATION") {
        return "Créer ma parcelle";
      }
      if (this.mode === "EDITION") {
        return "Modifier ma parcelle";
      }
      if (this.mode === "DUPLICATION") {
        return "Dupliquer ma parcelle";
      }
      return "Ma parcelle";
    },
    ilotModifiable() {
      return this.mode === "CREATION" || this.mode === "DUPLICATION";
    },
    readOnly() {
      return this.mode === "VISUALISATION";
    },
  },
  methods: {
    getNouvelleParcelle() {
      return {
        ilot: null,
        libelle: null,
        surfaceEmblavee: 0.0,
        surfaceRetiree: 0.0,
        etatParcelle: "INTENTION",
      };
    },
    /**
     * Options disponibles :
     * - mode = CREATION, VISUALISATION, EDITION ou DUPLICATION (VISUALISATION par défaut)
     * - parcelle = parcelle de travail (parcelle vide par défaut)
     */
    show(options) {
      this.mode = options?.mode ?? "VISUALISATION";
      this.parcelle = options?.parcelle
        ? UtilsService.deepCopy(options?.parcelle)
        : this.getNouvelleParcelle();
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      await this.$refs.inputIlot.validate();
      await this.$refs.inputLibelle.validate();
      await this.$refs.inputSurfaceEmblavee.validate();
      await this.$refs.inputSurfaceRetiree.validate();
      this.$refs["modal"].hide();
      this.resolvePromise(this.parcelle);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
