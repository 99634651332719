<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>
      <template>Fusion de parcelles</template>
    </template>
    <b-container>
      <SelectParcellesComponent
        ref="selectParcelles"
        label="Parcelles à fusionner :"
        :accept="parcellesFilter"
        v-model="parcelles"
      ></SelectParcellesComponent>
      <CustomInputComponent
        ref="inputLibelle"
        label="Libellé parcelle fusionnée :"
        rules="required|max:100"
        v-model="libelle"
      ></CustomInputComponent>
      <p class="mt-3">
        <em>
          Seules les parcelles non sem&eacute;es d'un m&ecirc;me ilot peuvent
          &ecirc;tre fusionn&eacute;es.
          <br />
          Les &eacute;ventuels emblavements pr&eacute;sents sur les parcelles
          fusionn&eacute;es seront supprim&eacute;s.
        </em>
      </p>
    </b-container>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import SelectParcellesComponent from "../inputs/SelectParcellesComponent.vue";
import CustomInputComponent from "../inputs/CustomInputComponent.vue";
import UtilsService from "../../services/utils.service";
export default {
  name: "FusionParcellesDialog",
  components: {
    SelectParcellesComponent,
    CustomInputComponent,
  },
  data: () => ({
    parcelles: [],
    libelle: null,
    resolvePromise: null,
    rejectPromise: null,
  }),

  computed: {
    parcellesFilter() {
      return (p) =>
        p.etatParcelle === "INTENTION" &&
        p.idIlot === (this.parcelles[0]?.idIlot ?? p.idIlot);
    },
  },
  methods: {
    show(options) {
      this.parcelles = options.parcelle
        ? [UtilsService.deepCopy(options.parcelle)]
        : [];
      this.libelle = options.parcelle?.libelle ?? "";
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    async validate() {
      await this.$refs.selectParcelles.validate();
      if (this.parcelles.length < 2) {
        this.$refs.selectParcelles.focus();
        return;
      }
      await this.$refs.inputLibelle.validate();
      this.$refs["modal"].hide();
      this.resolvePromise({
        libelleParcelle: this.libelle,
        idParcelles: this.parcelles.map((p) => p.id),
      });
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>
