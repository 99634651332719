<template>
  <b-modal ref="modal" scrollable @close="cancel">
    <template #modal-title>Modifier mon ilot</template>
    <template v-if="ilot">
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <b-form-group
        :id="`group-libelle-${id}`"
        label="Libellé ilot"
        :label-for="`input-libelle-${id}`"
      >
        <b-form-input
          :id="`input-libelle-${id}`"
          :name="`input-libelle-${id}`"
          type="text"
          v-model="ilot.libelle"
        ></b-form-input>
      </b-form-group>
    </template>
    <template #modal-footer>
      <b-container fluid>
        <b-row align-h="between">
          <b-col>
            <b-button block pill variant="outline-primary" @click="cancel">
              Annuler
            </b-button>
          </b-col>
          <b-col>
            <b-button block pill variant="primary" @click="validate">
              Enregistrer
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </template>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import ErrorComponent from "../ErrorComponent.vue";
export default {
  name: "IlotEditionDialog",
  components: { ErrorComponent },
  data: () => ({
    id: `ilot-${uuidv4()}`,
    error: null,
    ilot: null,
    resolvePromise: null,
    rejectPromise: null,
  }),
  methods: {
    getNouvelIlot() {
      return {
        codeRecolte: null,
        codeTiers: null,
        libelle: null,
        parcelles: [],
      };
    },
    show(options) {
      this.error = null;
      this.ilot = options.ilot || this.getNouvelIlot();
      this.$refs["modal"].show();
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    validate() {
      this.$refs["modal"].hide();
      this.resolvePromise(this.ilot);
    },
    cancel() {
      this.$refs["modal"].hide();
      this.resolvePromise(null);
    },
  },
};
</script>

<style scoped></style>
