<template>
  <b-card class="mobile-card">
    <b-container @click="$emit('view')">
      <b-row align-v="center">
        <b-col cols="auto" class="bg-light bg-left font-up" style="width: 80px">
          <div class="w-100">
            <MontantComponent :value="item.surfaceEmblavee"></MontantComponent>
            <br />
            ha
          </div>
        </b-col>
        <b-col>
          <div class="font-down">Ilot {{ item.ilot.libelle }}</div>
          <div>{{ item.libelle }}</div>
        </b-col>
        <b-col cols="auto" class="p-0" @click.stop>
          <ParcellesActionsComponent
            :item="item"
            @view="$emit('view')"
            @edit="$emit('edit')"
            @fusionner="$emit('fusionner')"
            @decouper="$emit('decouper')"
            @dupliquer="$emit('dupliquer')"
            @supprimer="$emit('supprimer')"
            @renommerIlot="$emit('renommerIlot')"
            @supprimerIlot="$emit('supprimerIlot')"
          ></ParcellesActionsComponent>
        </b-col>
      </b-row>
    </b-container>
  </b-card>
</template>

<script>
import MontantComponent from "../MontantComponent.vue";
import ParcellesActionsComponent from "../controls/ParcellesActionsComponent.vue";
export default {
  name: "ParcelleCard",
  components: { MontantComponent, ParcellesActionsComponent },
  props: ["item"],
};
</script>
