<template>
  <TableActionsComponent
    :editing="editing"
    :commentValue="commentValue"
    @cancel="$emit('cancel')"
    @validate="$emit('validate')"
    @comment="$emit('comment')"
    @view="$emit('view')"
    @edit="$emit('edit')"
  >
    <b-dropdown-item class="d-block d-md-none" @click="$emit('view')">
      <b-icon-eye></b-icon-eye>
      Visualiser la parcelle
    </b-dropdown-item>
    <b-dropdown-item class="d-block d-md-none" @click="$emit('edit')">
      <b-icon-pencil-square></b-icon-pencil-square>
      Modifier la parcelle
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="item.etatParcelle !== 'INTENTION'"
      @click="$emit('fusionner')"
    >
      <b-icon-arrows-angle-contract></b-icon-arrows-angle-contract>
      Fusionner la parcelle
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="item.etatParcelle !== 'INTENTION'"
      @click="$emit('decouper')"
    >
      <b-icon-arrows-angle-expand></b-icon-arrows-angle-expand>
      D&eacute;couper la parcelle
    </b-dropdown-item>
    <!--
    <b-dropdown-item @click="$emit('dupliquer')">
      <b-icon-files></b-icon-files>
      Dupliquer la parcelle
    </b-dropdown-item>
    -->
    <b-dropdown-item
      :disabled="item.etatParcelle !== 'INTENTION'"
      @click="$emit('supprimer')"
    >
      <b-icon-trash></b-icon-trash>
      Supprimer la parcelle
    </b-dropdown-item>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-item @click="$emit('renommerIlot')">
      <b-icon-fonts></b-icon-fonts>
      Renommer l'ilot
    </b-dropdown-item>
    <b-dropdown-item
      :disabled="item.ilotDisabled"
      @click="$emit('supprimerIlot')"
    >
      <b-icon-trash></b-icon-trash>
      Supprimer l'ilot
    </b-dropdown-item>
  </TableActionsComponent>
</template>

<script>
import TableActionsComponent from "./TableActionsComponent.vue";
export default {
  name: "ParcellesActionsComponent",
  components: {
    TableActionsComponent,
  },
  /**
   * item = objet parcelle
   * editing = true pour basculer en mode édition (false par défaut)
   * commentValue = valeur du commentaire (s'affiche au survol)
   */
  props: ["item", "editing", "commentValue"],
};
</script>
