<template>
  <section>
    <TitleBar tabsParent="recolte">
      Campagne {{ codeRecolte }}
      <template #toolbar-content>
        <b-button-group>
          <b-button
            pill
            variant="primary"
            @click="openParcelleEditor(null, 'CREATION')"
          >
            <b-icon-plus></b-icon-plus>
            Cr&eacute;er
          </b-button>
        </b-button-group>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
        &nbsp;
        <ExportButtonGroup
          @imprimer="() => {}"
          @exportExcel="() => {}"
          @exportCsv="() => {}"
        ></ExportButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <PaginatedTable
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
        @keydown.native.esc="load"
        @keydown.native.enter="onEnter"
      >
        <template #empty>
          <p>
            <strong>Votre parcellaire {{ codeRecolte }} est vide...</strong>
          </p>
          <p>
            Ajoutez facilement vos parcelles en important votre descriptif
            parcelles T&eacute;l&eacute;Pac ou en copiant votre parcellaire
            {{ codeRecolte - 1 }} !
          </p>
          <p>
            <b-form-file
              class="d-none"
              ref="inputFile"
              accept=".txt"
              @input="(file) => importerDescriptifParcellesTelepac(file)"
            ></b-form-file>
            <b-button
              pill
              variant="outline-primary"
              @click="openImportTelepacDialog"
            >
              Importer mon descriptif parcelles T&eacute;l&eacute;Pac&nbsp;
              <b-icon-download></b-icon-download>
            </b-button>
            <br />
            <b-link
              target="_blank"
              href="/doc/procedure_import_telepac_txt.pdf"
            >
              <b-icon-file-pdf class="icon-pdf"></b-icon-file-pdf>
              <em>
                Comment t&eacute;l&eacute;charger mes parcelles sur
                T&eacute;l&eacute;Pac ?
              </em>
            </b-link>
          </p>
          <p>
            <b-button pill variant="outline-primary" @click="copierParcellaire">
              Copier mon parcellaire {{ codeRecolte - 1 }}
              <b-icon-files></b-icon-files>
            </b-button>
          </p>
        </template>
        <template #mobile-card="{ item }">
          <ParcelleCard
            :item="item"
            @view="openParcelleEditor(item, 'VISUALISATION')"
            @edit="openParcelleEditor(item, 'EDITION')"
            @fusionner="openFusionParcellesEditor(item)"
            @decouper="openDecoupeParcelleEditor(item)"
            @dupliquer="dupliquerParcelle(item)"
            @supprimer="deleteParcelle(item)"
            @renommerIlot="openIlotEditor(item.ilot)"
            @supprimerIlot="deleteIlot(item.ilot)"
          ></ParcelleCard>
        </template>
        <template #cell(libelle)="{ item }">
          <CustomInputComponent
            :ref="`inputLibelle_${item.id}`"
            label=""
            :readOnly="item.etatParcelle !== 'INTENTION' || editing !== item.id"
            :quickEdit="item.etatParcelle === 'INTENTION'"
            rules="required|max:100"
            v-model="item.libelle"
            @edit="quickEdit(item.id)"
          ></CustomInputComponent>
        </template>
        <template #cell(surfaceEmblavee)="{ item }">
          <CustomInputNumberComponent
            :ref="`inputSurfaceEmblavee_${item.id}`"
            label=""
            :readOnly="item.etatParcelle !== 'INTENTION' || editing !== item.id"
            :quickEdit="item.etatParcelle === 'INTENTION'"
            rules="required|min_value:0.1"
            v-model="item.surfaceEmblavee"
            @edit="quickEdit(item.id)"
          ></CustomInputNumberComponent>
        </template>
        <template #cell(surfaceRetiree)="{ item }">
          <CustomInputNumberComponent
            :ref="`inputSurfaceRetiree_${item.id}`"
            label=""
            :readOnly="
              (item.etatParcelle !== 'INTENTION' &&
                item.etatParcelle !== 'SEMEE') ||
              editing !== item.id
            "
            :quickEdit="
              item.etatParcelle === 'INTENTION' || item.etatParcelle === 'SEMEE'
            "
            :rules="`required|min_value:0|max_value:${item.surfaceEmblavee}`"
            v-model="item.surfaceRetiree"
            @edit="quickEdit(item.id)"
          ></CustomInputNumberComponent>
        </template>
        <template #cell(actions)="{ item }">
          <ParcellesActionsComponent
            :item="item"
            :editing="editing === item.id"
            :commentValue="item.commentaire"
            @cancel="load()"
            @validate="validate(item)"
            @comment="openCommentaireEditor(item)"
            @view="openParcelleEditor(item, 'VISUALISATION')"
            @edit="openParcelleEditor(item, 'EDITION')"
            @fusionner="openFusionParcellesEditor(item)"
            @decouper="openDecoupeParcelleEditor(item)"
            @dupliquer="dupliquerParcelle(item)"
            @supprimer="deleteParcelle(item)"
            @renommerIlot="openIlotEditor(item.ilot)"
            @supprimerIlot="deleteIlot(item.ilot)"
          ></ParcellesActionsComponent>
        </template>
        <template #pagination>
          <!-- Nombre de parcelles : <strong>{{ nombreParcelles }}</strong> | -->
          Surface d'exploitation :
          <strong>
            <MontantComponent :value="surfaceExploitation"></MontantComponent>
            ha
          </strong>
        </template>
      </PaginatedTable>
      <IlotEditionDialog ref="ilotEditionDialog"></IlotEditionDialog>
      <ParcelleEditionDialog
        ref="parcelleEditionDialog"
      ></ParcelleEditionDialog>
      <FusionParcellesDialog
        ref="fusionParcellesDialog"
      ></FusionParcellesDialog>
      <DecoupeParcelleDialog
        ref="decoupeParcelleDialog"
      ></DecoupeParcelleDialog>
      <CommentaireDialog ref="commentaireEditionDialog"></CommentaireDialog>
      <ConfirmationDialog ref="confirmationDialog"></ConfirmationDialog>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ExportButtonGroup from "../../components/controls/ExportButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import ParcelleCard from "../../components/cards/ParcelleCard.vue";
import CustomInputComponent from "../../components/inputs/CustomInputComponent.vue";
import CustomInputNumberComponent from "../../components/inputs/CustomInputNumberComponent.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import ParcellesActionsComponent from "../../components/controls/ParcellesActionsComponent.vue";
import IlotEditionDialog from "../../components/dialogs/IlotEditionDialog.vue";
import ParcelleEditionDialog from "../../components/dialogs/ParcelleEditionDialog.vue";
import FusionParcellesDialog from "../../components/dialogs/FusionParcellesDialog.vue";
import DecoupeParcelleDialog from "../../components/dialogs/DecoupeParcelleDialog.vue";
import CommentaireDialog from "../../components/dialogs/CommentaireDialog.vue";
import ConfirmationDialog from "../../components/dialogs/ConfirmationDialog.vue";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "ParcellesView",
  components: {
    TitleBar,
    TableButtonGroup,
    ExportButtonGroup,
    ErrorComponent,
    PaginatedTable,
    ParcelleCard,
    CustomInputComponent,
    CustomInputNumberComponent,
    MontantComponent,
    ParcellesActionsComponent,
    IlotEditionDialog,
    ParcelleEditionDialog,
    FusionParcellesDialog,
    DecoupeParcelleDialog,
    CommentaireDialog,
    ConfirmationDialog,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.PARCELLES),
      error: null,
      page: null,
      editing: null,
    };
  },
  computed: {
    ...tableComputed,
    codeRecolte() {
      return this.$route.params.codeRecolte;
    },
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    nombreParcelles() {
      return this.$store.getters["decl/nombreParcelles"];
    },
    surfaceExploitation() {
      return this.$store.getters["decl/surfaceExploitation"];
    },
  },
  created,
  mounted() {
    this.load();
  },
  methods: {
    ...tableMethods,
    load() {
      try {
        this.busy = true;
        this.editing = null;

        // Copier les données du store et les formatter
        let store = this.$store;
        let parcelles = UtilsService.deepCopy(store.state.decl.parcelles);
        parcelles.forEach((p) => {
          p.ilot = store.getters["decl/ilot"](p.idIlot);
          p.ilotDisabled = parcelles
            .filter((p2) => p2.idIlot === p.idIlot)
            .reduce(
              (acc, next) => acc || next.etatParcelle !== "INTENTION",
              false
            );
        });

        // Filtrer la liste
        parcelles = parcelles.filter(this.getFilterFunction());

        // Trier et paginer le résultat
        this.page = UtilsService.readOnlyPage(
          parcelles,
          this.pageable.currentPage - 1,
          this.pageable.perPage,
          this.getSortFunction()
        );
      } catch (error) {
        this.page = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    async quickEdit(idParcelle) {
      this.editing = idParcelle;
      if (!idParcelle) {
        return;
      }
      await this.$nextTick();
      this.$refs[`inputSurfaceRetiree_${idParcelle}`].focus();
      this.$refs[`inputSurfaceEmblavee_${idParcelle}`].focus();
      this.$refs[`inputLibelle_${idParcelle}`].focus();
    },
    async onEnter() {
      let target = this.page.content.find((t) => t.id === this.editing);
      if (target) {
        this.validate(target);
      }
    },
    async openIlotEditor(ilot) {
      try {
        ilot = await this.$refs.ilotEditionDialog.show({ ilot });
        // Création/modification abandonnée
        if (!ilot) {
          return;
        }
        // Création/modification de l'ilot
        if (!ilot.id) {
          ilot.codeRecolte = this.codeRecolte;
          ilot.codeTiers = this.exploitationCourante.codeTiers;
        }
        await this.$store.dispatch("decl/createUpdateIlot", ilot);
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.load();
    },
    async deleteIlot(ilot) {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Supprimer l'ilot ?",
        messages: [
          `Etes-vous sûr de vouloir supprimer l'ilot <${ilot.libelle}> ? Cette action sera irréversible.`,
        ],
      });
      if (confirmation) {
        this.$store
          .dispatch("decl/deleteIlot", ilot.id)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    dupliquerParcelle(parcelle) {
      let copy = UtilsService.deepCopy(parcelle);
      copy.id = null;
      this.openParcelleEditor(copy, "DUPLICATION");
    },
    async openCommentaireEditor(parcelle) {
      let resultat = await this.$refs.commentaireEditionDialog.show({
        labelItems: "Parcelle",
        items: [`${parcelle.ilot.libelle} - ${parcelle.libelle}`],
        labelCommentaire: "Commentaire parcelle",
        commentaire: parcelle.commentaire,
      });
      if (resultat != null) {
        parcelle.commentaire = resultat;
        this.parcelleChanged(parcelle);
      }
    },
    async openParcelleEditor(parcelle, mode) {
      try {
        parcelle = await this.$refs.parcelleEditionDialog.show({
          parcelle,
          mode,
        });
        // Création/modification abandonnée
        if (!parcelle) {
          return;
        }
        // Création de l'ilot si inexistant
        if (parcelle.ilot && !parcelle.ilot.id) {
          let declarations = await this.$store.dispatch(
            "decl/createUpdateIlot",
            {
              codeRecolte: this.codeRecolte,
              codeTiers: this.exploitationCourante.codeTiers,
              libelle: parcelle.ilot.libelle,
            }
          );
          parcelle.ilot = declarations.ilots[0];
        }
        // Création/modification de la parcelle
        parcelle.idIlot = parcelle.ilot.id;
        await this.$store.dispatch("decl/createUpdateParcelle", parcelle);
      } catch (e) {
        this.error = UtilsService.handleError(e);
      }
      this.load();
    },
    async validate(parcelle) {
      await this.$refs[`inputLibelle_${parcelle.id}`]?.validate();
      await this.$refs[`inputSurfaceEmblavee_${parcelle.id}`]?.validate();
      await this.$refs[`inputSurfaceRetiree_${parcelle.id}`]?.validate();
      this.parcelleChanged(parcelle);
    },
    async parcelleChanged(parcelle) {
      await this.$store.dispatch("decl/createUpdateParcelle", parcelle);
      this.load();
    },
    async deleteParcelle(parcelle) {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: "Supprimer la parcelle ?",
        messages: [
          `Etes-vous sûr de vouloir supprimer la parcelle <${parcelle.libelle}> ? Cette action sera irréversible.`,
        ],
      });
      if (confirmation) {
        this.$store
          .dispatch("decl/deleteParcelle", parcelle.id)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    openImportTelepacDialog() {
      this.$refs["inputFile"].$el.children[0].click();
    },
    importerDossierTelepac(file) {
      let formData = new FormData();
      formData.append("dossier", file, file?.name);
      this.error = null;
      this.$store
        .dispatch("decl/importerDossierTelepac", {
          codeTiers: this.exploitationCourante.codeTiers,
          codeRecolte: this.codeRecolte,
          formData,
        })
        .catch((e) => (this.error = UtilsService.handleError(e)))
        .finally(() => this.load());
    },
    importerDescriptifParcellesTelepac(file) {
      let formData = new FormData();
      formData.append("fichier", file, file?.name);
      this.error = null;
      this.$store
        .dispatch("decl/importerDescriptifParcellesTelepac", {
          codeTiers: this.exploitationCourante.codeTiers,
          codeRecolte: this.codeRecolte,
          formData,
        })
        .catch((e) => (this.error = UtilsService.handleError(e)))
        .finally(() => this.load());
    },
    async copierParcellaire() {
      let confirmation = await this.$refs.confirmationDialog.show({
        title: `Copier votre parcellaire ${this.codeRecolte - 1} ?`,
        messages: [
          `Nous vous recommandons de finaliser d'abord vos saisies sur la 
          campagne en cours car les modifications ultérieures ne seront pas 
          automatiquement reportées sur la nouvelle campagne.`,
          `Etes-vous prêt à copier votre parcellaire ${this.codeRecolte - 1} ?`,
        ],
      });
      if (confirmation) {
        this.error = null;
        this.$store
          .dispatch("decl/copierParcellaire", {
            codeTiers: this.exploitationCourante.codeTiers,
            codeRecolte: this.codeRecolte,
          })
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async openFusionParcellesEditor(parcelle) {
      let payload = await this.$refs.fusionParcellesDialog.show({ parcelle });
      if (payload) {
        this.error = null;
        this.$store
          .dispatch("decl/fusionnerParcelles", payload)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
    async openDecoupeParcelleEditor(parcelle) {
      let payload = await this.$refs.decoupeParcelleDialog.show({ parcelle });
      if (payload) {
        this.error = null;
        this.$store
          .dispatch("decl/decouperParcelle", payload)
          .catch((e) => (this.error = UtilsService.handleError(e)))
          .finally(() => this.load());
      }
    },
  },
};
</script>
